import { defineComponent } from 'vue';
import DefaultDynamicStatusModalContent from '@/core/components/common/modals/DefaultDynamicStatusModalContent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'accept-estimate-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['loading'],
    components: {
        DefaultDynamicStatusModalContent,
        BaseModal
    },
    data() {
        return {
            type: 'deletemMemberConfirmation',
            content: {
                hasTwoButton: true,
                /* eslint-disable global-require */
                icon: `${require('@/assets/icons/trash-filled.svg')}`,
                /* eslint-disable global-require */
                statusText: 'Are you sure you want to delete this member?',
                hasDescription: true,
                descriptionText: 'Member will be permanently deleted.',
                buttonActiveLabel: 'Delete',
            }
        };
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
