import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import permissionConstant from '@/core/constants/permissions';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        UserThumbnail,
    },
    emits: ['show-delete-modal', 'update-member'],
    props: ['membersList'],
    data() {
        return {
            options: [
                {
                    value: permissionConstant.ADMIN_LABEL,
                    label: permissionConstant.ADMIN_LABEL,
                },
                {
                    value: permissionConstant.EDIT_LABEL,
                    label: permissionConstant.EDIT_LABEL,
                },
                {
                    value: permissionConstant.VIEW_LABEL,
                    label: permissionConstant.VIEW_LABEL,
                },
            ],
            value: []
        };
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USERS_STORE, ['user', 'isHosted', 'wsRole', 'isWsAdmin']),
    },
    async created() {
        this.initialized();
    },
    methods: {
        ...mapActions(USER_WORKSTATION_MEMBERS_STORE, [
            'setInviteMembersModal',
        ]),
        showDeleteModal(id) {
            this.$emit('show-delete-modal', id);
        },
        initialized() {
        },
        onUpdate(id, role) {
            const payload = {
                id,
                role
            };
            if (id && role) {
                this.$emit('update-member', payload);
            }
        }
    }
});
